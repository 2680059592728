import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import {Link} from "gatsby";

const scrollToPrijava = () => {
    const prijava = document.getElementById('prijava');

    // Scroll to the start of the element
    prijava.scrollIntoView({ behavior: 'smooth', block: 'start' });
}

function CuisineDescription(props) {
    return (
        <>
            <div className="about-wrapper ptb--120">
                <div className="container">
                    <div className="row align-items-center text-center text-lg-left">
                        <div className="col-lg-12">
                            <div className="section-title">
                                <h2 className="title"></h2>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 pr-lg-4">
                            <div className="">
                                <div className="section-title">
                                    <h3 className="title"><span className="text-primary">Top culinary chef.</span><br/>The finest local ingredients. Exceptional (Sitarjevec) stories.</h3>
                                    <h3 className="title"><span className="text-primary">A fusion of mining history and modernity</span>. A blend of unique environment and gastronomy.</h3>
                                    <p className="description mt-4"><strong>A 4-course menu</strong> will be prepared by the experienced team of <strong>chef Marko Železnik</strong>, who creates in Krčma na gradu Bogenšperk. This time, the culinary experience will take place in a truly special underground setting – the Sitarjevec Litija mine.</p>

                                    <p className="description">
                                        <strong>The unique history of the mine</strong> will serve as inspiration for crafting special dishes made from carefully selected local ingredients, including those that bear the highest quality certification of the Heart of Slovenia.
                                    </p>

                                    <p className="description">
                                        <strong>Culinary masterpieces,</strong> complemented by selected Slovenian wines and spirits, will be artistically harmonized with the natural vibrancy of the mine, which showcases extraordinary mineral formations.
                                    </p>

                                    <p className="description"><strong>Ingredients;</strong> local, seasonal, and authentic—are the foundation of healthy cuisine. Let yourself be enchanted even underground, where the world is different and <strong>food tastes even better.</strong></p>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xl-6 mt-4">
                            <div className="thumbnail">
                                <StaticImage
                                    src="../../assets/images/rudnik/rudarski-hod-3.jpg"
                                    alt="Sitarjevška malca in Škratov šmorn"
                                    placeholder="blurred"
                                    imgClassName="img-fluid w-100"
                                    loading="lazy"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="about-wrapper ptb--80 bg-primary text-white">
                <div className="container">
                    <div className="row justify-content-center mx-auto">
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="text-white mx-auto">
                                <h3 className="title text-center lead-2 text-white">The event is spiced up with stories of the mine as you walk along. The holistic experience
                                grounds you and sharpens your senses.</h3>
                                <h4 className="title text-center">You are invited to a gastronomic journey, a mine walk and storytelling!</h4>

                                <div className="mx-auto text-center">
                                    <button onClick={scrollToPrijava} className="btn-default btn-border btn-large btn-white mt--20">Sign up</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CuisineDescription;