import React from 'react';
import SEO from "../../../components/seo/Seo";
import HeaderEn from "../../../components/en/headers/Header";
import FooterEn from "../../../components/en/layouts/Footer";
import MineCulinaryHeaderEn from "../../../components/en/mine-culinary/MineCulinaryHeaderEn";
import MineCulinaryPartOneEn from "../../../components/en/mine-culinary/MineCulinaryPartOneEn";
import MineCulinaryPartTwoEn from "../../../components/en/mine-culinary/MineCulinaryPartTwoEn";
import MineCulinaryInfoBoxesEn from "../../../components/en/mine-culinary/MineCulinaryInfoBoxesEn";
import MineCulinaryFormEn from "../../../components/en/mine-culinary/MineCulinaryFormEn";

function GastronomicUnderground(props) {
    return (
        <div>
            <SEO title="Gastronomic Underground" description="A unique gastronomic underground journey in the heart of the Sitarjevec Litija mine"/>

            <HeaderEn/>
            <MineCulinaryHeaderEn/>
            <MineCulinaryPartOneEn/> 
            <MineCulinaryInfoBoxesEn/>
            <MineCulinaryPartTwoEn/>
            <MineCulinaryFormEn />

            <FooterEn/>
        </div>
    );
}

export default GastronomicUnderground;