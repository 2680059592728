import React, { useState } from "react";
import MineCulinaryContactFormEn from "./MineCulinaryContactFormEn";

function MineCulinaryFormEn(props) {
    const [messageSent, setMessageSend] = useState(false);
    return (
        <div className="rn-contact-top-area ptb--120 bg_color--3 mx-auto" id="prijava">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8">
                        <div className="">
                            <div className="section-title mb-5 text-center">
                                <h2 className="title text-center">How do I apply?</h2>
                                <div className="description mb-2">
                                    Click on the <strong>"APPLY"</strong> button and <strong>fill in the form</strong>. You will then receive an invoice, which you pay. 
                                    Upon receipt of proof of payment, the organiser will issue a voucher for the Sitarjevški hodi event.
                                </div>
                                <div className="description mb-2">
                                    Upon registration, your reservation is valid for 3 working days. During this time the invoice must be paid. 
                                    You will receive your invoice by e-mail. Payment of the invoice confirms the reservation.
                                </div>
                                <div className="description mb-5 mt-3" style={{fontSize: "0.925rem"}}>
                                    <strong>Tourist Information Centre Litija - TIC Litija.</strong> <br />
                                    Valvazorjev trg 10, 1270 Litija,
                                    info@visitlitija.si,
                                    051 312 739,
                                    070 700 484.
                                </div>
                                    {messageSent ? (
                                    <p>
                                        Thank you for your message. We will respond
                                        as soon as possible.
                                    </p>
                                ) : (
                                    <MineCulinaryContactFormEn
                                        setFormSuccessStatus={(value) =>
                                            setMessageSend(value)
                                        }
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MineCulinaryFormEn;